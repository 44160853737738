@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #faf8f0;
  --bgColor: #faf8f0;
  --textColor: #333333;
}
img {
  display: block;
  height: auto;
}
.dark {
  --background: #333333;
  --bgColor: #333333;
  --textColor: #faf8f0;
}
body {
  background: var(--background);
  color: var(--textColor);
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: scroll;
}
.h-60v {
  height: 60vh;
}
.borderStyle {
  border-radius: 0%;
  border: ridge 8px var(--textColor);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.5);
}
/* global.css */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
